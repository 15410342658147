// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-notre-approche-js": () => import("./../src/pages/notre-approche.js" /* webpackChunkName: "component---src-pages-notre-approche-js" */),
  "component---src-pages-notre-metier-js": () => import("./../src/pages/notre-metier.js" /* webpackChunkName: "component---src-pages-notre-metier-js" */),
  "component---src-pages-qui-sommes-nous-js": () => import("./../src/pages/qui-sommes-nous.js" /* webpackChunkName: "component---src-pages-qui-sommes-nous-js" */),
  "component---src-templates-offer-js": () => import("./../src/templates/offer.js" /* webpackChunkName: "component---src-templates-offer-js" */)
}

